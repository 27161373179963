@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.userMenuWrapper {
  margin-left: auto;

  .userMenuLoginBtn {
    display: inline-block;
    color: #252525;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8rem;
    padding: 1.2rem 4rem;
    border: 1px solid #423dcf;

    @include respond(tablet) {
      padding: 0.8rem 1.2rem;
      white-space: nowrap;
    }
  }
  .userMenuLoginBtn:hover {
    font-weight: 600;
    padding: 1.2rem 3.9rem;
  }
}